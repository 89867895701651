<script>
import CMExamConfigValidator from "../../classes/CMExamConfigValidator"

import FormErrors from '@/util/form-errors'
import errorsMixin from '@/mixins/errors.mixin'
import CMExamConfig, { CM_EXAM_CONFIG } from '../../classes/CMExamConfig'
export default {
  name: 'configCreateProvider',
  mixins: [errorsMixin],

  render() {
    return this.$scopedSlots.default({
      showing: this.showing,
      toggle: this.toggle,
      formErrors: this.formErrors,
      checkValidatorBeforeSend: this.checkValidatorBeforeSend,
      updateShowing: this.updateShowing,
      formData: this.formData,
      updateFormData: this.updateFormData
    })
  },

  inject: ['svc'],

  data:() => ({
    showing: false,
    processing: false,
    formErrors: new FormErrors(),
    formData: new CMExamConfig({})
  }),

  methods: {
    toggle() {
      this.showing = !this.showing
    },

    updateShowing(val) {
      this.showing = val
    },

    updateFormData(data) {
      this.formData = {
        ...this.formData,
        ...data
      }
    },

    checkValidatorBeforeSend(payload) {
      this.formErrors = payload.validator
      if(!this.formErrors.isValid()) {
        this.$notify({text:'Invalid fields', type: 'error'})
        return
      }
      this.send(payload)
    },

    async send({data, courseID}) {
      try {
        this.processing = true
        let res = await this.svc.createConfig(courseID, data)
        this.$emit('create', res)
        this.$notify({text: 'Exam created', type: 'success'})
        this.toggle()
      } catch (error) {
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      }
      finally {
        this.processing = false
      }
    }
  }
}
</script>
