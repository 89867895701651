import { REQUIRED } from '@/util/validation-rules';
import { DATE_DEFAULT_REGXP } from '@/util/const';

export default class CMExamConfigValidator {
  constructor(data, validator) {
    this.data = data
    this.validator = validator
  }

  validate() {
    this.validator.resetFields()
    return this.isValid()
  }

  isValid() {
    this.validateDate()

    this.validateDateFrom()
    this.validateDateTo()
    this.validateFutureDays()

    this.validateLocation()
    this.validateTimeTo()
    this.validateTimeFrom()

    this.validateLicenseType()

    return this.validator.isValid()
  }

  validateDateFrom() {
    if (!this.data.rangeMode) return;
    if(!this.validateRequired('examDateFrom', this.data.dateFrom)) return;
    if (!(DATE_DEFAULT_REGXP.test(this.data.dateFrom))) this.validator.invalidField('examDateFrom', 'Date should be in DD-MM-YYYY format');
  }

  validateDateTo() {
    if (!this.data.rangeMode) return;
    if(!this.validateRequired('examDateTo', this.data.dateTo)) return;
    if (!(DATE_DEFAULT_REGXP.test(this.data.dateTo))) this.validator.invalidField('examDateTo', 'Date should be in DD-MM-YYYY format');
    if (this.data.dateFrom > this.data.dateTo) this.validator.invalidField('examDateTo', 'End date should be greater than start date');
  }

  validateFutureDays() {
    if (this.data.rangeMode == null || (this.data.dateFrom && this.data.dateTo && this.data.rangeMode)) return
    if(!this.validateRequired('exam_date_future_days', this.data.exam_date_future_days)) return
  }

  validateDate() {
    if (this.data.rangeMode !== null) return true

    if (!this.validateRequired('examDate', this.data.date)) return
    if (!(DATE_DEFAULT_REGXP.test(this.data.date)))
      this.validator.invalidField(
        'examDate',
        'Date should be in DD-MM-YYYY format'
      )
  }

  validateLocation() {
    if(!this.validateRequired('location', this.data.location)) return
  }

  validateRequired(field, data) {
    let match = REQUIRED(data)
    if(match === true) return true
    this.validator.invalidField(field, match)
    return false
  }

  validateTimeFrom() {
    if(!this.validateRequired('timeFrom', this.data.timeFrom)) return
    if(!this.data.timeFrom || !this.data.timeTo) return
    let {timeTo, timeFrom} = this.getFormattedTime()
    if(timeFrom > timeTo)
      this.validator.invalidField('timeFrom', 'Time from must be less than time to')
  }

  getFormattedTime() {
    let timeFrom = new Date()
    let timeTo = new Date()
    timeFrom.setHours(...this.data.timeFrom.split(':'))
    timeTo.setHours(...this.data.timeTo.split(':'))
    return {
      timeFrom: timeFrom,
      timeTo: timeTo
    }
  }

  validateTimeTo() {
    if(!this.validateRequired('timeTo', this.data.timeTo)) return
    if(!this.data.timeFrom || !this.data.timeTo) return
    let {timeTo, timeFrom} = this.getFormattedTime()
    if(timeTo < timeFrom)
      this.validator.invalidField('timeTo', 'Time to must be more than time from')
  }

  validateLicenseType() {
    if(!this.data.licenseType || this.data.licenseType.length === 0) {
      this.validator.invalidField('licenseType', 'At least one license type should be chosen')
    }
  }
}
